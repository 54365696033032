<template>
  <div class="about">
    <div class="settingwarp">
      <header>
        <div class="g-header" style="background: rgb(14, 21, 48)">
          <div class="left" @click="changego">
            <van-icon name="arrow-left" color="#fff" size="20px" />
          </div>
          <div class="middle fs-18">Live Chat</div>
          <div class="right">
            <div class="bg-icon bg-icon-setting"></div>
          </div>
        </div>
        <div class="blockHeight"></div>
        <div class="g-content">
          
          <div class="chat-content" id="scrollBox">
            
            <div v-for="(item, index) in chatcontent" :key="index" class="container" :class="item.isAdmin == 1 ? 'darker' : ''">
              <p>{{ item.content }}</p>
            </div>

          </div>

          <div class="footer-chat">
            <div class="input-box">
              <input type="text" @input="event => valueChat = event.target.value" name="" id="" :value="valueChat" />
            </div>
            <div class="btn-chat" @click="sendChat">Gửi</div>
          </div>
        </div>
      </header>
    </div>
  </div>
</template>
<script>
import { showLoadingToast, closeToast } from "vant";
import homeApi from "@/api/home";

export default {
  name: "LiveChat",
  components: {},
  data() {
    return {
      chatcontent: [],
      valueChat: "",
      timeDataFun: null,
    };
  },
  methods: {
    scrollBottom() {
      // scroll bottom
      const container = document.querySelector("#scrollBox");
      container.scrollTop = container.scrollHeight;
    },
    sendChat() {
      console.log(this.valueChat)
      homeApi.sendChat(this.userId, this.valueChat).then((data)=> {
        console.log("Chat reuturn", data);
        this.valueChat = "";
      }).catch((err) => console.error(err))
    },
    changego() {
      this.$router.go(-1);
    },
  },
  computed: {
    userId() {
      console.log(this.$store.state.currentUserId);
      return this.$store.state.currentUserId;
    },
    userName() {
      console.log(this.$store.state.currentUser.username);
      return this.$store.state.currentUser.username;
    }
  },
  unmounted() {
    clearInterval(this.timeDataFun);
    this.timeDataFun = null;
  },
  created() {
    var that = this;

    showLoadingToast({
      message: this.$t("jiazaizhong"),
      forbidClick: true,
      duration: 0,
    });

    that.$nextTick(() => {
      this.timeDataFun = setInterval(() => {
        homeApi.loadChat(this.userId)
        .then((data) => {
          console.log("Chat data: ", data.data.data);
          this.chatcontent = data.data.data;
          console.log(this.chatcontent)
          closeToast();
        })
        .catch((err) => {console.error(err)});
      }, 2000);
    });
  },
};
</script>
<style lang="less" scoped>
.g-content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: scroll;
  text-align: center;
  color: #a0a0a0;
  position: absolute;
  // top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.footer-chat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.3rem 0.2rem;
  background-color: #30385d;
  border-top: 1px solid #3d5179;
}
.input-box {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 1rem;
  margin-right: 0.2rem;
}
.input-box input {
  background: #1a243f;
  padding: 0 0.3rem;
  color: white;
  font-size: 0.6rem;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 5px;
}

.btn-chat {
  background-color: #8a5278;
  color: white;
  font-weight: bold;
  height: 1rem;
  line-height: 1rem;
  width: 1.6rem;
  text-align: center;
  border-radius: 5px;
}

.btn-chat:hover {
  background-color: #a74d8b;
}

.chat-content {
  padding: 10px;
  height: calc(100vh - 3.18rem);
  overflow-y: auto;
}

.container {
  background-color: #4d37374d;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  width: 70%;
  text-align: right;
  margin-left: 30%;
  color: white;
  border: 1px solid #8a5278;
  font-size: 0.35rem;
}

.darker {
  background-color: #8a5278;
  text-align: left;
  margin-left: unset;
}

/* Clear floats */
.container::after {
  content: "";
  clear: both;
  display: table;
}

/* Style images */
.container img {
  float: left;
  max-width: 60px;
  width: 100%;
  margin-right: 20px;
  border-radius: 50%;
}

/* Style the right image */
.container img.right {
  float: right;
  margin-left: 20px;
  margin-right: 0;
}
</style>