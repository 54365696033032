<template>
  <van-swipe :autoplay="3000" class="swipe">
    <van-swipe-item v-for="(image, index) in images" :key="index">
      <img :src="apiURL + image.image" />
    </van-swipe-item>
  </van-swipe>
</template>

<script>
import homeApi from "@/api/home";
import { apiURL } from "@/utils/config";
import { showFailToast, closeToast } from "vant";
export default {
  data() {
    return {
      images: [],
      apiURL,
    };
  },
  computed: {},
  methods: {
    info() {
      homeApi
        .homeindex()
        .then((data) => {
          console.log(data.data);
          this.images = data.data.banner;
          closeToast();
        })
        .catch((err) => {
          closeToast();
          showFailToast(err.msg);
        });
    },
  },
  mounted() {},
  created() {
    this.info();
  },
};
</script>
<style lang="less" scoped>
.swipe {
  height: 4rem;
  img {
    height: 100%;
    width: 100%;
  }
}
</style>