import { createI18n } from 'vue-i18n'
import en from './config/en'
import vi from './config/vi'
console.log(createI18n)
const i18n = createI18n({
    legacy: false, // composition API
    globalInjection: true,
    locale: localStorage.getItem('locale') || 'vi',
    messages: {
        en,
        vi,
    },
})
export default i18n;