const vi = {
    jiantizhongwen: 'Tiếng Việt',
    xianlu: 'Server ',
    dianwei: 'Điểm thưởng',
    wodezichan: 'Số dư',
    touzikuaixun: 'Tin tức đầu tư',
    jiaoyiyonghu: 'Người dùng',
    jiaoyipinglei: 'Danh mục',
    danlunhuoli: 'Lợi nhuận',
    jiaoyiriqi: 'Ngày',
    dengluhouchakan: 'View after login',
    shouye: 'Trang chủ',
    jiaoyidating: 'Đầu tư',
    yucun: 'Nạp tiền',
    zoushi: 'Trend',
    wode: 'Cá nhân',
    zuiditouzi: 'Đầu tư tối thiểu',
    zuidijinru: 'Số dư tối thiểu',
    jichulicai: 'Basic Financing',
    zishenlicai: 'Senior Financing',
    dashilicai: 'Master Financing',
    zhuanjialicai: 'Expert Financing',
    基础理财: 'Basic Financing',
    资深理财: 'Senior Financing',
    大师理财: 'Master Financing',
    专家理财: 'Expert Financing',
    huiyuanquanyi: 'Lợi ích thành viên',
    zuoriyingli: 'Lợi nhuận hôm qua',
    zongzichan: 'Số dư',
    chucunjin: 'Số điểm',
    jinriyingli: 'Lợi nhuận hôm nay',
    zhuanchu: 'Rút tiền',
    cunru: 'Nạp tiền',
    zhanghuxinxni: 'Thông tin tài khoản',
    lishizhangdan: 'Lịch sử đầu tư',
    zijinminxi: 'Thông tin số dư',
    renwuzhongxin: 'Trung tâm nhiệm vụ',
    xiaoxigonggao: 'Tin tức thông báo',
    tuijianfenxiang: 'Chia sẻ',
    huodongzhongxin: 'Trung tâm hoạt động',
    jibenziliao: 'Thông tin cơ bản',
    dengluzhanghao: 'Tài khoản đăng nhập',
    nicheng: 'Nick name',
    shezhi: 'Cài đặt',
    genggaixianlu: 'Đổi server',
    guanyuwomen: 'Về VIN',
    xitongshezhi: 'Cài đặt hệ thống',
    yuyanshezhi: 'Cài đặt ngôn ngữ',
    tuichudenglu: 'Đăng xuất',
    bangdingyinhangka: 'Gán thẻ ngân hàng',
    bangdingxuniqianbao: 'Gán ví điện tử',
    anquanma: 'Mã bảo mật',
    xiugailenglumima: 'Thiết lập mật khẩu',
    yinhangkaguanli: 'Quản lý thẻ ngân hàng',
    tianjiayinhangka: 'Thêm thẻ ngân hàng',
    xuniqianbaoguanli: 'Quản lý ví điện tử',
    tianjiaxuniqianbao: 'Thêm ví điện tử',
    chiakren: 'Họ & tên',
    yinhangkakahao: 'Số thẻ',
    yinhangmingcheng: 'Tên ngân hàng',
    yhkTps: 'Vui lòng điền thông tin nhận tiền của bạn',
    nixiqianbaodizhi: 'Wallet address',
    xuniqianbaoleixing: 'Wallet Type',
    xnqbTps: ' Reminder: Please fill in the USDT address type, such as: trc20, erc20, omni. ',
    plchikaren: 'Nhập tên của bạn',
    plkahao: 'Nhập số tài khoản',
    plyinhangmingcheng: 'Nhập tên ngân hàng',
    plqbdz: 'Please enter the virtual wallet address',
    plqblx: 'Please enter virtual wallet type',
    shezhianquanma: 'Cài đặt mã bảo mật',
    yuananquanma: 'Mã bảo mật hiện tại',
    xinanquanma: 'Mã bảo mật mới',
    planquanma: 'Vui lòng nhập mã bảo mật hiện tại',
    plxinanquanma: 'Vui lòng nhập mã bảo mật mới',
    queren: 'Xác nhận',
    quxiao: 'Hủy',
    anquanmaTps: ' Vui lòng đặt mã bảo mật, không trùng với mật khẩu thẻ ngân hàng',
    anquanmaTpsTow: ' Kính gửi quý khách hàng, mã bảo mật này chính là mật khẩu khi quý khách chuyển khoản. Vì lý do bảo mật, vui lòng cố gắng không trùng với mật khẩu đăng nhập',
    xiugaidenglu: 'Thiết lập mật khẩu',
    jiumima: 'Mật khẩu cũ',
    xinmima: 'Mật khẩu mới',
    querenmima: 'Xác nhận mật khẩu',
    pljiumima: 'Vui lòng nhập mật khẩu cũ',
    plxinmima: 'Vui lòng nhập mật khẩu mới',
    plquerenmima: 'Vui lòng nhập lại mật khẩu',
    wancheng: 'Hoàn tất',
    kaishitouzi: 'Đầu tư ngay',
    kaiTps: 'Khi server hiện tại của bạn bị lag, hãy thử đổi sang server mới!',
    zuiyou: 'Ổn định',
    dangaqianxianlu: 'Server hiện tại',
    dangqianbanben: 'Phiên bản',
    banquansuoyou: 'Bản quyền',
    yinglizhendong: 'Thu nhập nhanh',
    gensuixitong: 'Mặc định',
    zhuanchujine: 'Số tiền',
    plzhuanchu: 'Nhập số tiền cần rút',
    zhuanchuTps: 'HD: Nếu bạn có bất kỳ yêu cầu nào vui lòng gọi hỗ trợ viên.',
    xuanzeyh: 'Vui lòng chọn ví điện tử để rút',
    xuanzehb: 'Vui lòng chọn ngân hàng để rút',
    tijiaoshenqing: 'Yêu cầu rút tiền',
    shuruanquanma: 'Vui lòng nhập mã bảo mật',
    xianshangcunru: 'Nạp tiền online',
    saomacunru: 'Nạp bằng USDT',
    wangyincunru: "Nạp bằng tài khoản ngân hàng",
    shijian: 'Thời gian',
    upshijian: 'Bắt đầu', // thời gian bd
    downshijian: 'Kết thúc', // thời gian kt
    wuTps: 'Không có dữ liệu ~',
    jiazaicx: 'Tải lại',
    di: 'Phiên ',
    lun: ' ',
    weiyingli: 'Không có lợi nhuận',
    yishouli: 'Đã chấp thuận',
    zhuangtai: 'Trạng thái',
    chongzhi: 'Đặt lại',
    riqi: 'Thời gian',
    CapActive: {
        chongzhi: 'Nạp',
        tixian: 'Rút',
        goumai: 'Mua',
        yingli: 'Lợi nhuận',
        zengsong: 'Quà tặng',
        weitongguo: 'Không hoàn tất',
        yitongguo: 'Hoàn tất',
        yijujue: 'Hủy',
    },
    zhanneixiaoxi: "Thông tin",
    pingtaigonggao: 'Thông báo',
    fuzhilanjie: "Sao chép liên kết",
    denglu: 'Đăng nhập',
    zhuce: 'Đăng ký',
    jizhumima: 'Nhớ',
    wangjimima: 'Quên mật khẩu',
    youkedenglu: 'Đăng nhập demo',
    zhucezhanghao: "Đăng ký tài khoản",
    plusername: 'Nhập số điện thoại',
    plpassword: 'Nhập mật khẩu',
    wanchengzhuce: 'Hoàn tất',
    yiyouzhanghao: 'Đã có tài khoản',
    qingdenglu: 'Đăng nhập',
    remenhuati: 'Tin tức hot',
    news1: 'Original',
    news2: 'Recommend',
    news3: 'Asset',
    renliulan: ' xem',
    hezhi: 'And value',
    lunshu: 'Phiên',

    做多: 'VICB-H',
    做空: 'VICB-E',
    多单: 'CTCB-1940',
    多双: 'CTCB-7430',
    空单: 'CTCB-2680',
    空双: 'CTCB-5651',
    平单: 'VICB-C',
    平双: 'VICB-G',
    极阴: 'VICB2023011',
    极阳: 'VICB2124899',
    qi: '',
    juli: 'distance',
    lunjiezhi: 'end',
    yifengpan: 'Dừng cược',
    yiguanbi: 'Đã đóng',
    yonghu: 'Người dùng',
    danlunjiaoyi: 'Trade',
    caozuo: 'Thao tác',
    pljoin: 'Vào phòng đầu tư thành công',
    pltingshou: 'Product has been discontinued',
    gengou: "Theo",
    quedinggengou: "Bạn có chắc chắn theo người này?",
    wanjia: "Người chơi",
    leibie: "Danh mục",
    jine: "Số tiền",
    gouru: 'Mua',
    zanweigouru: 'Chưa đầu tư',
    qigou: ' purchase',
    gourushuliang: 'SL Mua',
    changgui: 'Quy trình',
    shuzi: 'Chọn số',
    yinglihezhi: 'Lợi nhuận và kết quả',
    shouyijieshao: 'Income',
    lijicunru: 'Nạp tiền',
    kaihaojieguo: 'Lịch sử',
    kaihaoqushi: 'Biểu đồ',
    chanpingshuoming: 'Thông tin',
    qingchuhuancun: 'Xóa bộ đệm',
    youke: 'Visitor',
    gongxi: 'Chúc mừng ',
    zai: ' in the ',
    yingli: ' Lợi nhuận ',
    xiadanchenggong: 'Đầu tư thành công!',
    jiazaizhong: 'Đang tải...',
    guanbitg: 'Phiên hiện tại đang đóng, vui lòng chờ phiên mới!',
    xiangoudl: 'Phiên này đã đóng, kéo xuống để theo phiên mới hơn!',
    liaotiantip: 'Hệ thống đã bị cấm, vui lòng chọn mua',
    tishi: 'Thông tin',
    zhidao: 'OK',
    zhuanchusb: 'Không thể rút tiền',
    zhuanchusbs: 'Số dư không đủ, rút tiền không thành công',
    buyxq: 'Chi tiết mua',
    orderNo: 'Bill No.',
    plan_name: 'Thông tin đầu tư',
    buyxx: 'Thông tin mua',
    haoxx: 'Thông số chi tiết',
    buysj: 'Thời gian mua',
    yilou: 'thiếu sót',
    fzjqb: 'Đã sao chép vào clipboard',
    chanpin1: 'Thời gian bán: cứ 3,5 phút là một vòng, mở cửa lúc 10h00 cùng ngày và kết thúc lúc 02h00 ngày hôm sau.',
    chanpin2: 'Mỗi vòng có 3 số từ 0 đến 9. Tổng của 3 số là kết quả cuối cùng. Bạn có thể đầu tư vào kết quả: VICB-H, VICB-E, VICB-C, VICB-G, CTCB-1940, CTCB-7430, CTCB-2680, CTCB-5651, VICB202311, VICB2124899.',
    Tips: {
        wjmm: 'Quên mật khẩu, vui lòng liên hệ bộ phận chăm sóc khách hàng để sửa đổi!',
        dlcg: 'Đăng nhập thành công',
        tjcg: 'Gửi yêu cầu thành công',
        zanwu: 'Không có dữ liệu',
        buyizhi: 'Hai mật khẩu không nhất quán',
        zcbz: 'Số dư của bạn không đủ vui lòng nạp thêm tiền!',
        pltxje: 'Vui lòng nhập số tiền muốn rút',
        zdtx: 'Rút tối thiểu 1,000,000 VNĐ',
        youke: 'Kính gửi: Vui lòng đăng ký làm thành viên chính thức và hoạt động. ',
        szcg: 'Thiết lập thành công',
    },
}
export default vi;