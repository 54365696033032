import request from "@/utils/request";
export default {

  homeindex() {
    return request.get("/api/index/index");
  },

  Lotteryinfo(game_id) {
    return request.post("/api/Lottery/index", {
      game_id: game_id,
    });
  },

  heart(plan_id, game_id) {
    return request.post("/api/Lottery/heart", {
      plan_id: plan_id,
      game_id: game_id,
    });
  },

  loadChat(user_id) {
    return request.post("/api/Chat/byUser", {
      user_id: user_id,
    });
  },

  sendChat(user_id, content) {
    return request.post("/api/Chat/chat", {
      user_id: user_id,
      content: content,
    })
  },

  articleList() {
    return request.get("/api/index/articleList");
  },

  oddsList(plan_id) {
    return request.post("/api/Lottery/oddsList", {
      plan_id: plan_id,
    });
  },

  playOrder(issue, plan_id, game_id, play_type, money) {
    return request.post("/api/Lottery/playOrder", {
      issue: issue,
      plan_id: plan_id,
      game_id: game_id,
      play_type: play_type,
      money: money,
    });
  },
  issueList(game_id) {
    return request.post("/api/Lottery/issueList", {
      game_id: game_id,
    });
  },
  cand(game_id) {
    return request.post("/api/Lottery/cand", {
      game_id: game_id,
    });
  },
};
